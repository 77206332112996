<template>
    <div class="view-dashboard">
        <b-row>
            <b-col sm="12" md="6">
                <b-card header="View Dashboard - Mentee">
                    <b-overlay :show="loading">
                        <b-alert show v-if="mentees.length === 0">
                            You have no mentees assigned at the moment.
                        </b-alert>
                        <b-form inline v-if="mentees.length > 0">
                            <b-form-select :options="mentees" v-model="mentee" class="mr-2">
                                <template v-slot:first>
                                    <b-form-select-option :value="null" disabled>-- Please select --</b-form-select-option>
                                </template>
                            </b-form-select>
                            <b-button variant="primary" @click="openDashboard" :disabled="!mentee">Open Dashboard</b-button>
                        </b-form>
                    </b-overlay>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import Managers from "../../managers";
    import Providers from '../../providers';

    export default {
        name: "ViewDashboard",
        data() {
            return {
                mentees: [],
                loading: false,
                mentee: null,
            }
        },
        methods: {
            loadMentees() {
                this.loading = true;
                Providers.mentees.mentees().then((resp) => {
                    this.mentees = resp.body.map(m => ({
                        text: m.name,
                        value: m.userId
                    }));
                }).catch((resp) => {
                    this.$httpError('Failed to load mentees', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            openDashboard() {
                Managers.authentication.overrideLogin(this.mentee);
            }
        },
        created() {
            this.loadMentees();
        }
    }
</script>

<style scoped>

</style>